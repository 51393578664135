<template>
  <div class="goodsActivity">
    <!-- <el-button @click="getList" type="primary">主要按钮</el-button> -->
    <slideshow :slideshowList="slideshowList"></slideshow>
    <img class="debris" src="../../../static/debris.png" />
    <img
      class="Aboutbg"
      style="top: 43%; right: 0"
      src="../../../static/crane.png"
    />
    <img
      class="Aboutbg"
      style="top: 35%; right: 0"
      src="../../../static/crane.png"
    />
    <img
      class="Aboutbg"
      style="bottom: 18%; right: 0"
      src="../../../static/crane.png"
    />
    <img
      class="Aboutbg"
      style="top: 28%; left: 0"
      src="../../../static/wasteL.png"
    />
    <img
      class="Aboutbg"
      style="top: 56%; left: 0"
      src="../../../static/wasteL.png"
    />
    <img
      class="Aboutbg"
      style="bottom: 0; left: 0"
      src="../../../static/wasteL.png"
    />
    <img
      class="Aboutbg"
      style="top: 16%; right: 0"
      src="../../../static/wasteR.png"
    />
    <img
      class="Aboutbg"
      style="top: 53%; right: 0"
      src="../../../static/wasteR.png"
    />
    <img
      class="Aboutbg"
      style="bottom: 3%; right: 0"
      src="../../../static/wasteR.png"
    />
    <el-container>
      <el-main>
        <div>
          <div id="general" class="general" style="padding: 120px 80px 0 80px">
            <div style="width: 100%">
              <!-- <hr /> -->
              <div class="top-title">
                <img class="home-icon" src="../../../static/scenery/home.png" alt="" style="width:20;height:20;">
                <span style="margin-right:6px;font-weight:bold" @click="Jump('/',0)">首页</span>
                >
                <span style="margin-left:6px;" @click="Jump('/essential')">条子泥攻略</span>
                >
                <span style="margin-left:6px;color:#509AB1">基本信息</span>
              </div>
              <div>
                <div class="hr-more">
                    <div>条子泥景区门票</div>
                </div>
                <div class="essentialImg">
                  <img src="~static/essential1-new.png" alt="" />
                  <div
                    class="tzn_basic tzn_spsj"
                    v-html="basicDatafilter('售票时间')"
                  ></div>
                  <div
                    class="tzn_basic tzn_kfsj"
                    v-html="basicDatafilter('开放时间')"
                  ></div>
                  <div
                    class="tzn_basic tzn_gpj"
                    v-html="basicDatafilter('挂牌价')"
                  ></div>
                  <div
                    class="tzn_basic tzn_yhj"
                    v-html="basicDatafilter('优惠价')"
                  ></div>
                  <div
                    class="tzn_basic tzn_sfyj"
                    v-html="basicDatafilter('收费依据')"
                  ></div>
                  <div
                    class="tzn_basic tzn_jddh"
                    v-html="basicDatafilter('监督电话')"
                  ></div>
                  <div
                    class="tzn_basic tzn_gpxz"
                    v-html="basicDatafilter('购票须知')"
                  ></div>
                  <div
                    class="tzn_basic tzn_xgyhxx"
                    v-html="basicDatafilter('相关优惠政策')"
                  ></div>
                </div>
              </div>
             <div>
                <div class="hr-more">
                    <div>园内交通费</div>
                </div>
                <div class="essentialImg">
                  <img src="~static/essential2-new.png" alt="" />
                  <div
                    class="tzn_basic tzn_ddggc"
                    v-html="basicDatafilter('电动观光车')"
                  ></div>
                  <div
                    class="tzn_basic tzn_yc"
                    v-html="basicDatafilter('游船')"
                  ></div>
                  <div
                    class="tzn_basic tzn_dljjj"
                    v-html="basicDatafilter('导览机讲解')"
                  ></div>
                  <div
                    class="tzn_basic tzn_dyjj"
                    v-html="basicDatafilter('导览讲解')"
                  ></div>
                </div>
              </div>
              <div>
                <div class="hr-more">
                    <div>涨落潮时刻参考表</div>
                </div>
                <div class="essentialImg">
                  <img src="~static/essential3-new.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <!-- <div v-for="(item, index) in basicInformationList" :key="item.id">
                <div class="hr-more">
                    <div>{{item.name}}</div>
                </div>
                <div class="essentialImg">
                    <img :style="{'vertical-align': 'middle', width:( index == 3 ? '100%' : '')}" :src="item.imageLinks" alt="">
                </div>
          </div> -->
          <div class="essentialImg">
            <img
              style="vertical-align: middle; width: 100%"
              src="~static/essential4-new.png"
              alt=""
            />
            <div
              class="tzn_basic tzn_mpyyzd"
              v-html="basicDatafilter('条子泥景区门票预约制度')"
            ></div>
            <div
              class="tzn_basic tzn_ylxz"
              v-html="basicDatafilter('游览须知')"
            ></div>
            <!-- <div class="tzn_ZD">
                <div class="tzn_zd_t">条子泥景区门票预约制度</div>
                <div v-html="basicDatafilter('条子泥景区门票预约制度')"></div>
                <div class="tzn_zd_t">游览须知</div>
                <div v-html="basicDatafilter('游览须知')"></div>
              </div> -->
          </div>
        </div>
        <div>
        </div>
        <div
          ref="backTopBG"
          style=" 
            position: fixed;
            display: none;
            right: 56px;
            color: #509ab1;
            font-weight: bold;
            text-align: center;
            bottom: 130px;
            z-index: 9;
          "
          @click="backTop()"
        >
          <img src="../../../static/backTopbg.png" alt="" />
          <p>回到顶部</p>
        </div>
        <leftFloat :floatList="floatList" />
      </el-main>
    </el-container>
  </div>
</template>

<script>
import * as api from "@/api/test/test";
export default {
  name: "About",
  data() {
    return {
      floatList: {
        list: [
          { name: "基本信息", url: "flush" },
          { name: "舌尖美味", url: "/foodList" },
          { name: "酒店住宿", url: "/scenicSpot" },
          { name: "景区互动", url: "/interaction" },
          { name: "行程推荐", url: "/travel" },
        ],
        title: "条子泥攻略",
      },
      currentPage: 1,
      hotelType:0,
      activeNum: false,
      slideshowList: ``,
      count:``,
      basicInformationList:``,
      tzn_basicData: [],
      basicData:``,
    }
  },
  watch:{
    activeNum(n,o){
      this.currentPage = 1
    }
  },
  methods: {
     basicDatafilter(e){
       let data,title
       this.tzn_basicData.forEach(item => {
          if( item.title == e ){
            if(e == '挂牌价' || e == '优惠价'){
                data = item.remark
              }else{
                data = item.content
              }
            }
        });
      return this.decodeHTML(data)
    },
    decodeHTML(str) {
      var s = "";
      if (!str || str.length == 0) return "";
      s = str.replace(/&amp;/g, "&");
      s = s.replace(/&lt;/g, "<");
      s = s.replace(/&gt;/g, ">");
      s = s.replace(/&nbsp;/g, " ");
      s = s.replace(/&#39;/g, "'");
      s = s.replace(/&quot;/g, '"');
      return s;
    },
    backTop() {
      const that = this;
      let ispeed = Math.floor(that.scrollTop / 5);
      document.documentElement.scrollTop = document.body.scrollTop =
        that.scrollTop + ispeed;
      if (that.scrollTop === 0) {
        clearInterval(timer);
      }
    },
  },
  created() {
    api.slideshow().then((res) => {
      this.slideshowList = res;
    });
    api.basicInformation().then(({data}) => {
      this.basicInformationList = data
    })
  },
  mounted() {
    api.tzn_basic({page:0,size:100,basiclnfoType:0}).then(({data,code}) => {
      if(code == 0){
        this.tzn_basicData = data
      }
    })
  },
  destroyed() {
  },
};
</script>
<style scoped>
.tzn_ylxz{
  /* font-size: 18px !important;
  height: 460px;
  width: 1100px !important;
  top: 680px;
  margin-left: 0 !important;
  z-index: 1; */
  font-size: 1.125rem !important;
  height: 28%;
  width: 57% !important;
  top: 49%;
  margin-left: 0 !important;
  z-index: 1;
}
.tzn_mpyyzd{
  /* font-size: 18px !important;
  height: 380px;
  width: 1100px !important;
  top: 200px;
  margin-left: 0 !important;
  z-index: 1; */
  font-size: 1.125rem !important;
  height: 24%;
  width: 57% !important;
  top: 14%;
  margin-left: 0 !important;
  z-index: 1;
}
.tzn_ZD{
  position: absolute;
    top: 180px;
    width: 57%;
    height: 900px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size:  1.125rem;
    color: #666;
}
.tzn_ZD>.tzn_zd_t{
  font-size: 35px;
    font-weight: bold;
    color: #56a4bd;
}
.tzn_dyjj{
  height: 145px;
  top: 585px;
  margin-left: -100px !important;
  width: 190px !important;
}
.tzn_dljjj{
  height: 60px;
  top: 475px;
  margin-left: -90px !important;
  width: 140px !important;
  text-align: start;
}
.tzn_yc{
  height: 25px;
  top: 400px;
  margin-left: -100px !important;
  width: 200px !important;
}
.tzn_ddggc{
  height: 25px;
  top: 327px;
  margin-left: -100px !important;
  width: 200px !important;
}
.tzn_jddh{
  height: 25px;
  top: 694px;
}
.tzn_gpxz{
  height: 170px;
  top: 770px;
  margin-left: 95px !important;
}
.tzn_sfyj{
  height: 25px;
  top: 622px;
}
.tzn_spsj{
  height: 25px;
  top: 330px;
}
.tzn_kfsj{
  height: 25px;
  top: 404px;
}
.tzn_gpj{
    height: 25px;
    width: 150px !important;
    top: 558px;
    left: 36% !important;
}
.tzn_yhj{
    height: 25px;
    width: 150px !important;
    top: 558px;
    left: 57% !important;
}
.tzn_xgyhxx{
    height: 690px;
    bottom: 268px;
}
.tzn_gpxz>>>p,.tzn_xgyhxx>>>p,.tzn_mpyyzd>>>p,.tzn_ylxz>>>p,.tzn_ZD>>>P{
  text-align: start;
}
.tzn_basic{
  width: 710px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-size: 25px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-left: 90px;
  padding: 15px;
  background: rgb(255, 255, 255);
  color: #666;
}
.essentialImg{
    text-align: center;
    position: relative;
}
.essentialImg>img{
  width: 1320px;
}
.el-pagination {
  display: flex;
  justify-content: center;
  margin: 18px 0 51px;
}
.QRcode {
  width: 1200px;
  margin: 50px auto;
  display: flex;
  justify-content: space-between;
}
.QRcode > .QRcodebg {
  width: 340px;
  height: 340px;
  border-radius: 50%;
}
.QRcodebg2 {
  box-sizing: border-box;
  padding: 35px 60px;
  width: 816px;
  height: 392px;
  background: url(../../../static/scenicSpotBg.png) no-repeat;
}
.QRcodebg2 > h4 {
  margin: 10px 0;
}
.QRcode > div > P {
  line-height: 30px;
  margin: 10px 0;
  /* text-align: center; */
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.PastPhotos {
  background: url(../../../static/PastPhotosbg.png) no-repeat;
  height: 737px;
}
.imgBox {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  /* height: 950px;
  overflow-y: auto; */
}
>>> .el-breadcrumb {
  line-height: 92px;
  font-size: 24px;
}
.top-title {
  background-size: cover;
  background-image: url(/static/img/title.ab49d158.png);
  background-repeat: no-repeat;
  letter-spacing: 5px;
  width: 1200px;
  margin: 0 auto;
  text-align: left;
  line-height: 92px;
  font-size: 24px;
}
.home-icon {
  width: 20px;
  height: 20px;
  margin: 0 16px 0 53px;
}
.top-title>span:hover{
    cursor: pointer;
    color: #509AB1;
}
.morebg {
  display: block;
  width: 201px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  color: #509ab1;
  margin: 0 auto;
  background: url("../../../static/morebg.png") no-repeat;
}
.tabs {
  display: flex;
  justify-content: center;
}
.tabs > div {
  width: 230px;
  height: 59px;
  line-height: 59px;
  text-align: center;
  margin: 15px;
  font-size: 23px;
  color: #509ab1;
  background: url("../../../static/tab.png") no-repeat;
}
.tabsB {
  background: url("../../../static/tabAcitve.png") no-repeat !important;
  background-size: cover !important;
  color: #f6d397 !important;
}
.Hrbg2 {
  display: flex;
  justify-content: space-evenly;
  width: 1241px;
  height: 151px;
  line-height: 135px;
  text-align: center;
  margin: 0 auto;
  font-size: 24px;
  font-family: PangMenZhengDao-Cu;
  font-weight: bold;

  color: #509ab1;
  background: url("../../../static/Hrbg3.png") no-repeat;
}
.Hrbg2 > div > img {
  vertical-align: middle;
  padding-right: 10px;
}
.Aboutbg {
  position: absolute;
  z-index: -1;
}
.introduce_tabs {
  display: flex;
  justify-content: space-evenly;
  text-align: center;
}
.introduce_tabs p,
.textSpan {
  font-size: 25px;
  font-family: PangMenZhengDao-Cu;
  font-weight: bold;
  color: #509ab1;
}
.debris {
  width: 100%;
  margin-top: -157px;
  z-index: 8;
  background: transparent;
  position: relative;
}
.active {
  background-color: #509ab1;
}
.greatDeeds_r h3 {
  width: 55px;
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #509ab1;
  line-height: 41px;
}
.greatDeeds_r h5 {
  width: 357px;
  height: 18px;
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  line-height: 22px;
}
.greatDeeds_r > div {
  position: absolute;
  left: -50vw;
  width: 100vw;
  display: flex;
  justify-content: space-evenly;
}
.greatDeeds_bzt {
  height: 100%;
  position: relative;
  left: 50%;
  width: 2px;
  border: none;
  background: darkgray;
}
.greatDeeds_bzt > h2 {
  position: absolute;
  right: -19px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  background: #509ab1;
  border-radius: 25px;
  text-align: center;
}
img[class^="general"] {
  position: absolute;
  z-index: -99;
}
.greatDeeds {
  background: url("../../../static/greatDeedsimg.png") no-repeat center;
  background-size: cover;
}
.Hcontent {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px 160px;
  background: url("../../../static/img3bg.png") no-repeat 100% 100%;
  background-size: cover;
  height: 600px;
}
.content-text {
  width: 142px;
  height: 231px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background: url("../../../static/img13.png") no-repeat 100% 100%;
}
.content-text > p {
  width: 30px;
  font-size: 21px;
  font-weight: bold;
  line-height: 29px;
  color: #317981;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/* 静态资源 */
.content-grid {
  width: 220px;
  height: 320px;
  z-index: 12;
  position: relative;
}

.introduceText {
  white-space: pre-wrap;
  display: -webkit-box;
  -webkit-line-clamp: 11;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-top: 10px;
}
.introduce {
  background-size: cover;
}
.el-tabs__content > div {
  display: flex;
}
.greatDeeds_2 {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.greatDeeds_2 > div {
  padding-left: 40px;
  background-position-y: 50%;
}

>>> .el-tabs__content {
  height: 300px;
}
.hr-more {
  white-space: nowrap;
  letter-spacing: 3px;
  line-height: 250px;
  width: 452px;
  height: 250px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  top: -25px;
  font-weight: bold;
  vertical-align: middle;
  text-align: center;
  background: url("../../../static/Hrbg.png");
  font-size: 26px;
  font-family: PangMenZhengDao-Cu;
  color: #509ab1;
  text-shadow: ;
}
.hr-more img {
  margin: 0 6px;
}
.hr-more span {
  width: 119px;
  height: 9px;
  font-size: 12px;
  font-family: Arial;
  font-weight: 400;
  color: #b3b3b3;
  line-height: 24px;
}
.goodsActivity {
  position: relative;
}
.general {
  display: flex;
  /* box-shadow: 0px -7px 18px 0px rgb(0 0 0 / 10%); */
  padding-left: 20px;
  margin-bottom: 50px;
  /* background: url("../../../static/generalbg.png") no-repeat -6% -49%; */
}

.el-main {
  padding: 0;
  overflow-x: hidden;
}
</style>